import {
  ApolloClient,
  FieldReadFunction,
  InMemoryCache,
  concat,
  createHttpLink,
  Reference,
  isReference,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import type { EntityStore } from "@apollo/client/cache";
import { captureMessage, captureException } from "@sentry/nextjs";
import { camelCase } from "lodash";
import tokenService from "utils/TokenService";
import { GQL_PREFIX } from "utils/config";
import { isServerError } from "./getServerErrorMessage";
import makeNodesLimitOffsetPagination, {
  DEFAULT_CONNECTION_KEY_ARGS,
} from "./pagination";

type HeadersContext = {
  headers?: {
    [header: string]: string | undefined;
  };
};

const authLink = setContext(
  async (_, { headers }: HeadersContext): Promise<HeadersContext> => {
    const authHeaders = await tokenService.getAuthorizationHeaders();
    return {
      headers: {
        ...authHeaders,
        ...headers,
      },
    };
  },
);

type AdminLinkContext = HeadersContext & {
  isAdminQuery?: boolean;
};

const adminLink = setContext(
  (_, { headers, isAdminQuery }: AdminLinkContext) => {
    if (!isAdminQuery) return { headers };
    return { headers: { ...headers, "X-Admin-Role": true } };
  },
);

type ActiveBrandIdContext = HeadersContext & {
  activeBrandId?: UUID;
};

const activeBrandIdContext = setContext(
  (_, { headers, activeBrandId }: ActiveBrandIdContext) => {
    if (!activeBrandId) return { headers };
    return { headers: { ...headers, "X-Active-Brand-Id": activeBrandId } };
  },
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response, forward }) => {
    const { operationName } = operation || {};

    if (networkError) {
      if (
        isServerError(networkError) &&
        networkError.statusCode === 401 &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        networkError.result?.errors?.[0]?.message === "jwt expired"
      ) {
        // If the operation only failed because the JWT is expired, try again
        // (this will cause the request to hit the auth link again, which
        // _should_ trigger a JWT refresh).
        return forward(operation);
      }
      captureException(networkError, {
        extra: { response, operationName, ...networkError },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        tags: { operation_name: operationName },
      });
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const { message, extensions, path } = error || {};
        const { code, sentryId, shouldSentryCapture } = (extensions || {}) as {
          code?: string;
          sentryId?: string;
          shouldSentryCapture?: boolean;
        };
        if (!sentryId && shouldSentryCapture !== false) {
          // If the error has a sentryId, it was already captured on the server
          // with more details. We only capture the ones without here...
          captureMessage(message || "Unhandled GraphQL Error Occurred", {
            level: "error",
            extra: { error, response, operationName },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tags: { operation_name: operationName, code },
            fingerprint: [
              "{{ default }}",
              String(operationName),
              String(path?.join(".")),
              String(code),
            ],
          });
        }
      });
    }
  },
);

const link = createHttpLink({
  uri: `${GQL_PREFIX}/graphql`,
  credentials: "omit",
});

function createToReferenceForByIdField(
  typename: string,
  argName = "id",
  cacheKeyId = "id",
): FieldReadFunction {
  return (existing: unknown, { args, toReference }) => {
    if (existing || existing === null) return existing;
    if (!args?.[argName]) return;
    return toReference({
      __typename: typename,
      [cacheKeyId]: args?.[argName] as string,
    });
  };
}

type ListOfIdsArgs<IdFieldName extends string = "id"> = {
  filter?: { [K in IdFieldName]: { in?: string[] } | undefined };
};

function getIsListOfIdsArgs<IdFieldName extends string = "id">(
  args: unknown,
  idFieldName: IdFieldName,
): args is ListOfIdsArgs<IdFieldName> {
  if (!args || typeof args !== "object") return false;

  const argNames = Object.keys(args);
  if (
    !("filter" in args) ||
    !args.filter ||
    typeof args.filter !== "object" ||
    argNames.length !== 1 ||
    argNames[0] !== "filter"
  ) {
    return false;
  }

  const filterArgNames = Object.keys(args.filter || {});
  if (
    filterArgNames.length !== 1 ||
    filterArgNames[0] !== idFieldName ||
    !(idFieldName in args.filter)
  ) {
    return false;
  }

  const idFilterArgNames = Object.keys(
    args.filter?.[idFieldName as keyof typeof args.filter] || {},
  );
  if (idFilterArgNames.length !== 1 || idFilterArgNames[0] !== "in")
    return false;

  return true;
}

function createListOfIdsField<IdFieldName extends string = "id">(
  typename: string,
  idFieldName: IdFieldName = "id" as IdFieldName,
): FieldReadFunction {
  return (existing: unknown, { args, toReference, canRead }) => {
    if (existing || !getIsListOfIdsArgs(args, idFieldName)) return existing;

    if (!args?.filter?.[idFieldName]?.in?.length) return;

    const result = args.filter[idFieldName].in.map((id) =>
      toReference({ __typename: typename, [idFieldName]: id }),
    );

    if (result.every(canRead)) return result;

    return existing;
  };
}

function createIdToReferenceField(
  typename: string,
  keyFieldName = "id",
  idFieldName?: string,
): FieldReadFunction<Reference> {
  return (existing, { readField, toReference }) => {
    if (existing || existing === null) return existing;
    const id = readField(idFieldName || `${camelCase(typename)}Id`);
    if (id && (typeof id === "string" || typeof id === "number")) {
      return toReference({ __typename: typename, [keyFieldName]: id });
    }
  };
}

// TODO: This shouldn't rely on private/protected methods
function getObjectFromCache(cache: InMemoryCache, cacheId: string) {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const store = cache.data as EntityStore;
  // @ts-ignore
  return store.lookup(cacheId);
  /* eslint-enable @typescript-eslint/ban-ts-comment */
}

function createReferenceToIdField(
  referenceFieldName: string,
  keyFieldName = "id",
): FieldReadFunction<UUID | null> {
  return (existing, { cache, readField }) => {
    if (existing || existing === null) return existing;

    // We're using this indirect means of first getting the object directly from
    // the cache instead of relying on `readField` to avoid an infinite loop
    // that can be caused when both this method and `createIdToReferenceField`
    // are applied simultaneously.

    const id = readField(keyFieldName);
    const typename = readField("__typename");
    if (
      !(
        typeof id === "string" &&
        typeof typename === "string" &&
        id &&
        typename
      )
    ) {
      return;
    }

    const cacheId = cache.identify({
      __typename: typename,
      [keyFieldName]: id,
    });
    if (!cacheId) return;

    const obj = getObjectFromCache(cache, cacheId);

    if (!obj) return;

    const ref = obj[referenceFieldName];
    if (ref === null) return null;

    if (ref && isReference(ref)) {
      const idFromRef = ref.__ref?.split(":")?.[1];
      if (idFromRef) return idFromRef;
    }
  };
}

const DEFAULT_KEY_ARGS_WITH_BRAND_ID = [
  ...DEFAULT_CONNECTION_KEY_ARGS,
  "brandId",
];

const client = new ApolloClient({
  link: concat(
    errorLink,
    concat(concat(concat(authLink, activeBrandIdContext), adminLink), link),
  ),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          chainRetailersList: {
            read: createListOfIdsField("ChainRetailer"),
          },
          salesPlanRetailersList: {
            // Silence warnings when removing items
            merge: false,
          },
          brand: {
            read: createToReferenceForByIdField("Brand"),
          },
          brandsList: {
            read: createListOfIdsField("Brand"),
          },
          brandChainRetailer: {
            read: createToReferenceForByIdField("BrandChainRetailer"),
          },
          brandChainRetailers: makeNodesLimitOffsetPagination(),
          brandChainRetailerSku: {
            read: createToReferenceForByIdField("BrandChainRetailerSku"),
          },
          brandDocument: {
            read: createToReferenceForByIdField("BrandDocument"),
          },
          brandDocuments: makeNodesLimitOffsetPagination(),
          brandUser: {
            read: createToReferenceForByIdField("BrandUser"),
          },
          brandUsers: makeNodesLimitOffsetPagination(),
          brandUsersList: {
            read: createListOfIdsField("BrandUser"),
          },
          chainRetailer: {
            read: createToReferenceForByIdField("ChainRetailer"),
          },
          chainRetailers: makeNodesLimitOffsetPagination(),
          chainRetailersForBrand: makeNodesLimitOffsetPagination(
            DEFAULT_KEY_ARGS_WITH_BRAND_ID,
          ),
          chainRetailersForBrandList: createListOfIdsField("ChainRetailer"),
          changeEvent: {
            read: createToReferenceForByIdField(
              "ChangeEvent",
              "eventId",
              "eventId",
            ),
          },
          changeEvents: makeNodesLimitOffsetPagination(),
          comments: makeNodesLimitOffsetPagination(),
          commentsForBrand: makeNodesLimitOffsetPagination(
            DEFAULT_KEY_ARGS_WITH_BRAND_ID,
          ),
          dataSourceDocument: {
            read: createToReferenceForByIdField(
              "DataSourceDocument",
              "id",
              "id",
            ),
          },
          dataSourceDocuments: makeNodesLimitOffsetPagination(),
          dataSourceProvider: {
            read: createToReferenceForByIdField(
              "DataSourceProvider",
              "name",
              "name",
            ),
          },
          dataSourceTypeGroup: {
            read: createToReferenceForByIdField(
              "DataSourceTypeGroup",
              "name",
              "name",
            ),
          },
          dataSourceType: {
            read: createToReferenceForByIdField(
              "DataSourceType",
              "name",
              "name",
            ),
          },
          dataSourceTypesList: {
            read: createListOfIdsField("DataSourceType", "name"),
          },
          dataSourceTypeBrandMapping: {
            read: createToReferenceForByIdField("DataSourceTypeBrandMapping"),
          },
          dataSourceTypeBrandMappings: makeNodesLimitOffsetPagination(),
          dataSourceTypeBrandMappingsList: {
            read: createListOfIdsField("DataSourceTypeBrandMapping"),
          },
          salesPlanRetailer: {
            read: createToReferenceForByIdField("SalesPlanRetailer"),
          },
          partnerBySlug: {
            read: createToReferenceForByIdField("Partner", "slug", "slug"),
          },
          pitchableAccount: {
            read: createToReferenceForByIdField(
              "PitchableAccount",
              "brandId",
              "brandId",
            ),
          },
          pitchableCampaigns: makeNodesLimitOffsetPagination(),
          pitchableCampaignRetailerContacts: makeNodesLimitOffsetPagination(),
          productLine: {
            read: createToReferenceForByIdField("ProductLine"),
          },
          productLinesList: {
            read: createListOfIdsField("ProductLine"),
          },
          documentFolderBySlug: {
            read: createToReferenceForByIdField(
              "DocumentFolder",
              "slug",
              "slug",
            ),
          },
          distributionCenter: {
            read: createToReferenceForByIdField("DistributionCenter"),
          },
          distributionCentersList: {
            read: createListOfIdsField("DistributionCenter"),
          },
          productCategoryDepartmentSubDepartment: {
            read: createToReferenceForByIdField(
              "ProductCategoryDepartmentSubDepartment",
            ),
          },
          productCategoryDepartmentSubDepartmentsList: {
            read: createListOfIdsField(
              "ProductCategoryDepartmentSubDepartment",
            ),
          },
          orderingPlatform: {
            read: createToReferenceForByIdField("OrderingPlatform"),
          },
          orderingPlatformsList: {
            read: createListOfIdsField("OrderingPlatform"),
          },
          orderingPlatformsForBrand: makeNodesLimitOffsetPagination(
            DEFAULT_KEY_ARGS_WITH_BRAND_ID,
          ),
          orderingPlatformsForBrandList: {
            read: createListOfIdsField("OrderingPlatform"),
          },
          salesPlan: {
            read: createToReferenceForByIdField("SalesPlan"),
          },
          salesPlanLiftEvents: makeNodesLimitOffsetPagination(),
          salesPlanLiftEventsList: createListOfIdsField("SalesPlanLiftEvent"),
          sku: {
            read: createToReferenceForByIdField("Sku"),
          },
          skusList: {
            read: createListOfIdsField("Sku"),
          },
          promotion: {
            read: createToReferenceForByIdField("Promotion"),
          },
          promotions: makeNodesLimitOffsetPagination(),
          promotionsList: createListOfIdsField("Promotion"),
          purchaseOrder: {
            read: createToReferenceForByIdField("PurchaseOrder"),
          },
          purchaseOrders: makeNodesLimitOffsetPagination(),
          purchaseOrdersList: createListOfIdsField("PurchaseOrder"),
          purchaseOrderSku: {
            read: createToReferenceForByIdField("PurchaseOrderSku"),
          },
          pitchableCampaignsList: createListOfIdsField("PitchableCampaign"),
          pitchableCampaignRetailerContactsList: createListOfIdsField(
            "PitchableCampaignRetailerContact",
          ),
          retailer: {
            read: createToReferenceForByIdField("Retailer"),
          },
          retailerLocation: {
            read: createToReferenceForByIdField("RetailerLocation"),
          },
          retailerContact: {
            read: createToReferenceForByIdField("RetailerContact"),
          },
          retailerContactEmailAddress: {
            read: createToReferenceForByIdField("RetailerContactEmailAddress"),
          },
          retailerContactPhoneNumber: {
            read: createToReferenceForByIdField("RetailerContactPhoneNumber"),
          },
          retailerChannelsList: createListOfIdsField("RetailerChannel"),
          retailerRegionsList: createListOfIdsField("RetailerRegion"),
          receivedEmailMessage: {
            read: createToReferenceForByIdField(
              "ReceivedEmailMessage",
              "emailMessageId",
              "emailMessageId",
            ),
          },
          receivedEmailMessages: makeNodesLimitOffsetPagination(),
          receivedPitchableEmailMessageTagTypesList: createListOfIdsField(
            "ReceivedPitchableEmailMessageTagType",
          ),
          user: {
            read: createToReferenceForByIdField("User"),
          },
          users: makeNodesLimitOffsetPagination(),
          usersList: {
            read: createListOfIdsField("User"),
          },
        },
      },
      BrandChainRetailer: {
        fields: {
          brand: {
            read: createIdToReferenceField("Brand"),
          },
          brandId: {
            read: createReferenceToIdField("brand"),
          },
          chainRetailer: {
            read: createIdToReferenceField("ChainRetailer"),
          },
          chainRetailerId: {
            read: createReferenceToIdField("chainRetailer"),
          },
        },
      },
      BrandChainRetailerSku: {
        fields: {
          brandChainRetailer: {
            read: createIdToReferenceField("BrandChainRetailer"),
          },
          brandChainRetailerId: {
            read: createReferenceToIdField("brandChainRetailer"),
          },
          sku: {
            read: createIdToReferenceField("Sku"),
          },
          skuId: {
            read: createReferenceToIdField("sku"),
          },
        },
      },
      BrandChainRetailerComment: {
        keyFields: ["commentId"],
      },
      ChangeEvent: {
        keyFields: ["eventId"],
      },
      DataSourceProvider: {
        keyFields: ["name"],
      },
      DataSourceTypeGroup: {
        keyFields: ["name"],
        fields: {
          dataSourceProvider: {
            read: createIdToReferenceField("DataSourceProvider", "name"),
          },
          dataSourceProviderId: {
            read: createReferenceToIdField("dataSourceProvider", "name"),
          },
        },
      },
      DataSourceType: {
        keyFields: ["name"],
        fields: {
          dataSourceTypeGroup: {
            read: createIdToReferenceField("DataSourceTypeGroup", "name"),
          },
          dataSourceTypeGroupId: {
            read: createReferenceToIdField("dataSourceTypeGroup", "name"),
          },
        },
      },
      SalesPlanRetailer: {
        fields: {
          salesPlanRetailerSkusList: {
            // Silence warnings when removing items
            merge: false,
          },
        },
      },
      Partner: {
        keyFields: ["slug"],
      },
      Promotion: {
        fields: {
          chainRetailer: {
            read: createIdToReferenceField("ChainRetailer"),
          },
          chainRetailerId: {
            read: createReferenceToIdField("chainRetailer"),
          },
        },
      },
      ApprovedPitchableCampaign: {
        keyFields: ["pitchableCampaignId"],
      },
      PitchableAccount: {
        keyFields: ["brandId"],
      },
      DistributionCenter: {
        fields: {
          orderingPlatform: {
            read: createIdToReferenceField("OrderingPlatform"),
          },
        },
      },
      DocumentFolder: {
        keyFields: ["slug"],
      },
      ForwardedEmailMessage: {
        keyFields: ["emailMessageId"],
      },
      ReceivedEmailMessage: {
        keyFields: ["emailMessageId"],
      },
      ReceivedPitchableEmailMessage: {
        keyFields: ["receivedEmailMessageId"],
      },
      SalesPlan: {
        fields: {
          brand: {
            read: createIdToReferenceField("Brand"),
          },
          indyRetailerSkusBySalesPlansIdList: {
            // Silence warnings when removing items
            merge: false,
          },
        },
      },
      Sku: {
        fields: {
          productLine: {
            read: createIdToReferenceField("ProductLine"),
          },
        },
      },
      ProductCategoryDepartmentSubDepartment: {
        fields: {
          qualifiedLabel: {
            read: (existing: unknown, { args, readField }) => {
              if (existing) return existing;
              const prefix = readField("qualifiedLabelPref");
              const categoryName = readField("categoryName");
              const separator =
                typeof args?.separator === "string" ? args?.separator : " > ";
              if (
                typeof prefix === "string" &&
                typeof categoryName === "string" &&
                prefix &&
                categoryName
              ) {
                return `${prefix}${separator}${categoryName}`;
              }
            },
          },
        },
      },
      PurchaseOrder: {
        fields: {
          purchaseOrderRevision: {
            read: createIdToReferenceField("PurchaseOrderRevision"),
          },
          purchaseOrderSkusList: {
            // Silence warnings when removing items
            merge: false,
          },
        },
      },
      RetailerLocation: {
        fields: {
          retailer: {
            read: createIdToReferenceField("Retailer"),
          },
        },
      },
      RetailerContact: {
        fields: {
          retailerLocation: {
            read: createIdToReferenceField("RetailerLocation"),
          },
        },
      },
      RetailerContactEmailAddress: {
        fields: {
          retailerContact: {
            read: createIdToReferenceField("RetailerContact"),
          },
        },
      },
      User: {
        fields: {
          fullName: {
            read: (existing: string | undefined | null, { readField }) => {
              if (existing || existing === null) return existing;
              const firstName = readField("firstName");
              const lastName = readField("lastName");
              if (
                (typeof firstName !== "string" && firstName !== null) ||
                (typeof lastName !== "string" && lastName !== null)
              ) {
                return;
              }
              return `${firstName || ""} ${lastName || ""}`.trim();
            },
          },
        },
      },
    },
  }),
});

export default client;
