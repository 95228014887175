import type { LinkProps } from "next/link";

export type Url = LinkProps["href"];

export const BRAND_INDEX = "/";

export const COMMENTS_INDEX = "/comments";

/* PRODUCTS */

export const PRODUCTS_INDEX = "/products";

export const PRODUCTS_IMPORT = `${PRODUCTS_INDEX}/import` as const;

export function selectedProductLine(productLineId: UUID): Url;
export function selectedProductLine(
  productLineId: UUID | null | undefined,
): Url | undefined;
export function selectedProductLine(
  productLineId: UUID | null | undefined,
): Url | undefined {
  if (!productLineId) return;
  return { pathname: PRODUCTS_INDEX, query: { productLineId } };
}

export function selectedSku(skuId: UUID): Url;
export function selectedSku(skuId: UUID | null | undefined): Url | undefined;
export function selectedSku(skuId: UUID | null | undefined): Url | undefined {
  if (!skuId) return;
  return { pathname: PRODUCTS_INDEX, query: { skuId } };
}

/* CUSTOMERS */

export const CUSTOMERS_INDEX = "/customers";

export const CUSTOMER_PAGE = `${CUSTOMERS_INDEX}/[orderingPlatformId]` as const;

export const CUSTOMER_WAREHOUSE_PAGE =
  `${CUSTOMER_PAGE}/warehouses/[distributionCenterId]` as const;

export const CUSTOMERS_IMPORT = `${CUSTOMERS_INDEX}/import` as const;

export function customerPage(orderingPlatformId: UUID): Url;
export function customerPage(
  orderingPlatformId: UUID | null | undefined,
): Url | undefined;
export function customerPage(
  orderingPlatformId: UUID | null | undefined,
): Url | undefined {
  if (!orderingPlatformId) return;
  return { pathname: CUSTOMER_PAGE, query: { orderingPlatformId } };
}

export function customerWarehousePage(
  orderingPlatformId: UUID,
  distributionCenterId: UUID,
): Url;
export function customerWarehousePage(
  orderingPlatformId: UUID | null | undefined,
  distributionCenterId: UUID | null | undefined,
): Url | undefined;
export function customerWarehousePage(
  orderingPlatformId: UUID | null | undefined,
  distributionCenterId: UUID | null | undefined,
): Url | undefined {
  if (!orderingPlatformId || !distributionCenterId) return;
  return {
    pathname: CUSTOMER_WAREHOUSE_PAGE,
    query: { orderingPlatformId, distributionCenterId },
  };
}

/* RETAILERS */

export const BRAND_RETAILERS_INDEX = "/retailers";

export const BRAND_RETAILER_PAGE =
  `${BRAND_RETAILERS_INDEX}/[retailerId]` as const;

export function brandRetailerPage(brandRetailerId: UUID): Url;
export function brandRetailerPage(
  brandRetailerId: UUID | null | undefined,
): Url | undefined;
export function brandRetailerPage(
  brandRetailerId: UUID | null | undefined,
): Url | undefined {
  if (!brandRetailerId) return;
  return {
    pathname: BRAND_RETAILER_PAGE,
    query: { retailerId: brandRetailerId },
  };
}

/* PROMOTIONS */

export const BRAND_PROMOTIONS_INDEX = "/promotions";

export const BRAND_PROMOTIONS_ALL = `${BRAND_PROMOTIONS_INDEX}/all` as const;

export const BRAND_PROMOTIONS_IMPORT =
  `${BRAND_PROMOTIONS_INDEX}/import` as const;

/* DOCUMENTS */

export const DOCUMENTS_INDEX = "/documents";

/* PRICING CALCULATOR */

export const PRICING_CALCULATOR_INDEX = `${PRODUCTS_INDEX}/pricing` as const;

/* SALES PLANNER */

export const SALES_PLAN_INDEX = "/sales-plan";

export const SALES_PLAN_PAGE =
  `${SALES_PLAN_INDEX}/[[...salesPlanId]]` as const;

export function salesPlanPage(salesPlanId: UUID): Url;
export function salesPlanPage(
  salesPlanId: UUID | null | undefined,
): Url | undefined;
export function salesPlanPage(
  salesPlanId: UUID | null | undefined,
): Url | undefined {
  if (!salesPlanId) return;
  return { pathname: SALES_PLAN_PAGE, query: { salesPlanId } };
}

/* PURCHASE ORDERS */

export const PURCHASE_ORDERS_INDEX = "/purchase-orders";

export const PURCHASE_ORDERS_DASHBOARD = PURCHASE_ORDERS_INDEX;

export const PURCHASE_ORDERS_TABLE = `${PURCHASE_ORDERS_INDEX}/all` as const;

export const PURCHASE_ORDERS_IMPORT =
  `${PURCHASE_ORDERS_INDEX}/import` as const;

/* ANALYTICS */

export const ANALYTICS_INDEX = "/analytics";

export const ANALYTICS_DASHBOARD = ANALYTICS_INDEX;

export const ANALYTICS_DATA_SOURCES = `${ANALYTICS_INDEX}/source` as const;

export function analyticsDataSourcePage(dataSourceTypeName: string) {
  return `${ANALYTICS_DATA_SOURCES}?dataSourceType=${dataSourceTypeName}`;
}

export const ANALYTICS_MAPPING = `${ANALYTICS_DATA_SOURCES}/mapping` as const;

export function analyticsMappingPage(dataSourceProviderName: string) {
  return `${ANALYTICS_MAPPING}?dataSourceProvider=${dataSourceProviderName}`;
}

/* EMAIL CAMPAIGNS */

export const EMAIL_CAMPAIGNS_INDEX = "/campaigns";

export const EMAIL_CAMPAIGN_PAGE =
  `${EMAIL_CAMPAIGNS_INDEX}/[campaignId]` as const;

export const NEW_CAMPAIGN_PAGE = `${EMAIL_CAMPAIGNS_INDEX}/new` as const;

export function emailCampaignPage(campaignId: UUID): Url;
export function emailCampaignPage(
  campaignId: UUID | null | undefined,
): Url | undefined;
export function emailCampaignPage(
  campaignId: UUID | null | undefined,
): Url | undefined {
  if (!campaignId) return;
  return { pathname: EMAIL_CAMPAIGN_PAGE, query: { campaignId } };
}

/* PITCHABLE FOR RETAILERS */

export const PITCHABLE_MESSAGE_ACTION = "/message-action";

export const PITCHABLE_SAMPLE_ACTION =
  `${PITCHABLE_MESSAGE_ACTION}/sample` as const;

export function pitchableSampleAction(
  pitchableCampaignEmailId: UUID,
  pitchableCampaignRetailerContactId?: string | null,
): Url;
export function pitchableSampleAction(
  pitchableCampaignEmailId: UUID | undefined | null,
  pitchableCampaignRetailerContactId?: string | null,
): Url | undefined;
export function pitchableSampleAction(
  pitchableCampaignEmailId: UUID | undefined | null,
  pitchableCampaignRetailerContactId?: string | null,
): Url | undefined {
  if (!pitchableCampaignEmailId) return;
  return {
    pathname: PITCHABLE_SAMPLE_ACTION,
    query: {
      pceid: pitchableCampaignEmailId || undefined,
      pcrcid: pitchableCampaignRetailerContactId || undefined,
    },
  };
}

export function pitchableSellSheet(pitchableCampaignId: UUID): string;
export function pitchableSellSheet(
  pitchableCampaignId: UUID | null | undefined,
): string | undefined;
export function pitchableSellSheet(
  pitchableCampaignId: UUID | null | undefined,
) {
  if (!pitchableCampaignId) return;
  return `/server/pitchable/campaign/${pitchableCampaignId}/documents/sell-sheet`;
}

/* SETTINGS */

export const SETTINGS_BASE = "/settings";

const BRAND_SETTINGS_BASE = `${SETTINGS_BASE}/brand` as const;
export const BRAND_SETTINGS = `${BRAND_SETTINGS_BASE}/details` as const;
export const BRAND_USER_SETTINGS = `${BRAND_SETTINGS_BASE}/users` as const;

export const USER_PROFILE = `${SETTINGS_BASE}/user` as const;

/* ADMIN */

export const ADMIN_INDEX = "/admin";

export const ADMIN_BRANDS_INDEX = `${ADMIN_INDEX}/brands` as const;

export const ADMIN_BRANDS_MANAGE = ADMIN_BRANDS_INDEX;

export const ADMIN_BRANDS_DISTRIBUTOR_PRESENCE =
  `${ADMIN_BRANDS_INDEX}/distributor-presence` as const;

export const ADMIN_BRANDS_RETAILER_PRESENCE =
  `${ADMIN_BRANDS_INDEX}/retailer-presence` as const;

export const ADMIN_CHAIN_RETAILERS_INDEX =
  `${ADMIN_INDEX}/chain-retailers` as const;

export function adminChainRetailerPage(retailerId: UUID): Url;
export function adminChainRetailerPage(
  retailerId: UUID | null | undefined,
): Url | undefined;
export function adminChainRetailerPage(
  retailerId: UUID | null | undefined,
): Url | undefined {
  if (!retailerId) return;
  return {
    pathname: ADMIN_CHAIN_RETAILERS_INDEX,
    query: {
      selectedId: retailerId,
      search: retailerId,
      status: "all",
    },
  };
}

export const ADMIN_ANALYTICS_INDEX = `${ADMIN_INDEX}/analytics` as const;

export const ADMIN_ANALYTICS_DATA_SOURCES =
  `${ADMIN_ANALYTICS_INDEX}/source` as const;

export function adminAnalyticsDataSourcePage(dataSourceTypeName: string) {
  return `${ADMIN_ANALYTICS_DATA_SOURCES}?dataSourceType=${dataSourceTypeName}`;
}

export const ADMIN_ANALYTICS_SEGMENTING =
  `${ADMIN_ANALYTICS_INDEX}/segmenting` as const;

export const ADMIN_AUDIT = `${ADMIN_INDEX}/audit` as const;

export const ADMIN_RETAILERS_INDEX = `${ADMIN_INDEX}/retailers` as const;

export function adminRetailerPage(retailerId: UUID): Url;
export function adminRetailerPage(
  retailerId: UUID | null | undefined,
): Url | undefined;
export function adminRetailerPage(
  retailerId: UUID | null | undefined,
): Url | undefined {
  if (!retailerId) return;
  return {
    pathname: ADMIN_RETAILERS_INDEX,
    query: {
      selectedRetailerId: retailerId,
      search: retailerId,
      status: "all",
    },
  };
}

export const ADMIN_CAMPAIGNS_INDEX = `${ADMIN_INDEX}/campaigns` as const;

export const ADMIN_CAMPAIGNS_DASHBOARD =
  `${ADMIN_CAMPAIGNS_INDEX}/dashboard` as const;

export const ADMIN_CAMPAIGNS_INBOX = `${ADMIN_CAMPAIGNS_INDEX}/inbox` as const;

export const ADMIN_CAMPAIGNS_MANAGE_INDEX =
  `${ADMIN_CAMPAIGNS_INDEX}/manage` as const;
export const ADMIN_CAMPAIGN_PAGE =
  `${ADMIN_CAMPAIGNS_MANAGE_INDEX}/[campaignId]` as const;

export function adminCampaignPage(campaignId: UUID): Url;
export function adminCampaignPage(
  campaignId: UUID | null | undefined,
): Url | undefined;
export function adminCampaignPage(
  campaignId: UUID | null | undefined,
): Url | undefined {
  if (!campaignId) return;
  return { pathname: ADMIN_CAMPAIGN_PAGE, query: { campaignId } };
}
